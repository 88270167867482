import * as React from 'react';
import { __, __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  SanitasFinishedOrder as FinishedOrder,
  FlexCol,
  SanitasInfoText as InfoText,
  prop,
  ZemplinLoaderWrapper,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import LazyLoad from 'react-lazyload';
import styled from '../../theme/styled-components';
import {
  fetchFinishedOrder,
  finishedOrderDataSelector,
  finishedOrderIsFetchingSelector,
} from '../MyAccount/myAccountSlice';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import { getPaymentUrl, isError, shouldRedirect } from '../../utilities/order';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { handlePurchase } from './cartSlice';
import {
  ecoTrackCart,
  ecomailPurchase,
  heurekaPurchase,
} from '@bart.sk-ecommerce/react-online-marketing';

interface Props {
  dispatch: any;
  orderData: ThenArg<typeof API.getOrder>;
  isFetching: boolean;
  params: any;
}

class FinishedOrderContainer extends React.Component<Props> {
  private isSendingToGA: boolean;

  public static async getInitialProps(props) {
    const {
      dispatch,
      params: { orderPublicId },
    } = props;
    dispatch(
      setBreadCrumbPath(BreadCrumbType.CART, {
        url: `${__r(
          'routes:dokoncena-objednavka',
          '/dokoncena-objednavka',
        )}/${orderPublicId}`,
      }),
    );
    try {
      await dispatch(fetchFinishedOrder(orderPublicId));
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  constructor(props) {
    super(props);

    this.isSendingToGA = false;
  }

  public async componentDidMount() {
    const { orderData, isFetching } = this.props;

    if (orderData && !this.isSendingToGA && !isFetching) {
      // await this.updateOrderAnalytics();
    }
  }

  public async componentDidUpdate(prevProps) {
    const {
      params: { orderPublicId },
    } = this.props;
    if (
      prop(prevProps, 'orderData.order_id') !==
        prop(this.props, 'orderData.order_id') &&
      prop(this.props, 'orderData.public_id') === orderPublicId
    ) {
      await this.updateOrderAnalytics();
    }
  }

  // public async componentDidUpdate(prevProps): Promise<void> {
  //   const {
  //     params: { orderPublicId },
  //   } = this.props;
  //   if (
  //     prop(this.props, 'orderData.public_id') &&
  //     prop(prevProps, 'orderData.order_id') !==
  //       prop(this.props, 'orderData.order_id') &&
  //     prop(this.props, 'orderData.public_id') === orderPublicId
  //   ) {
  //     await this.updateOrderAnalytics();
  //   }
  // }

  public updateOrderAnalytics = async (): Promise<void> => {
    const {
      orderData: order,
      isFetching,
    }: { orderData: any; isFetching: boolean } = this.props;
    if (!this.isSendingToGA && !shouldRedirect(order)) {
      this.isSendingToGA = true;
      this.props.dispatch(handlePurchase(order));
    }
    if (order && order.analytics_sended === 0) {
      try {
        heurekaPurchase(order);

        ecomailPurchase(order, 'sanitas');
        ecoTrackCart(null, null, null);
      } catch (e) {
        console.log('heureka', e);
      }
    }
  };

  public render() {
    const { orderData, isFetching } = this.props;

    if (isFetching) {
      return <ZemplinLoaderWrapper />;
    }

    if (!orderData) {
      return (
        <InfoText
          type={InfoType.ERROR}
          info={__('Takáto objednávka neexistuje')}
        />
      );
    } else if (shouldRedirect(orderData)) {
      const redirectUrl = getPaymentUrl(
        orderData.payment.payment_type,
        orderData.public_id,
      );
      if (redirectUrl && window && window.location && window.location.href) {
        window.location.href = redirectUrl;
        return null;
      }
    } else if (isError()) {
      return (
        <>
          <Wrapper className="container container--wide">
            <MetaTags tags={{ title: __('Chyba') }} />
            <div>Error</div>
          </Wrapper>
          <InfoBanner />
        </>
      );
    }

    return (
      <>
        <BreadCrumb withWrapper={true} />
        <Wrapper className="container container--wide">
          <MetaTags tags={{ title: __('Ďakujeme za váš nákup!') }} />
          <FinishedOrder order={orderData} isFetching={isFetching} />
        </Wrapper>
      </>
    );
  }
}

const Wrapper = styled(FlexCol)`
  margin: 0 auto;
  width: 100%;
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    orderData: finishedOrderDataSelector(state),
    isFetching: finishedOrderIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'FinishedOrder' })(FinishedOrderContainer),
);

import React, { useState } from 'react';
import { __, __r } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  FlexRow,
  FlexRowCenter,
  FlexRowCenterVertical,
} from 'eshop-defaults';
import { Link } from 'react-router';
import API from '../../services/API';
import { Loader } from '../_helpers/Loader/Loader';
import {
  IconType,
  SvgIcon,
} from 'eshop-defaults/lib/components/Sanitaske/General/SvgIcon';
import Input from '../_helpers/forms/Input/Input';
import { Button } from 'eshop-defaults/lib/components/Sanitaske/General/Button';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const emailIsValid = () => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length > 0 && emailRegex.test(email.toLowerCase());
  };

  const formIsValid = () => {
    return emailIsValid() && termsAccepted;
  };

  const submitNewsletter = async e => {
    e.preventDefault();
    setIsFetching(true);

    const subscribe = await API.subscribeNewsletter(
      { engine: 'mailchimp' },
      {
        email,
        first_name: '',
        last_name: '',
        source: '',
        status: '',
      },
    );

    setIsFetching(false);
    if (subscribe && email) {
      setIsSubscribed(true);
    }
  };

  const handleClick = e => {
    e.preventDefault();
    setTermsAccepted(!termsAccepted);
  };

  return (
    <Wrapper>
      <Left>
        <TopTitleWrapper>
          {' '}
          <IconWrapper>
            <SvgIcon
              icon={IconType.newsLetter}
              alt="Prejsť na košík"
              width={30}
              height={30}
              marginRight={12}
              fill={'#3962A8'}
              viewBox={'0 0 32 32'}
            />
          </IconWrapper>
          <Title>{__('Odber noviniek')}</Title>{' '}
        </TopTitleWrapper>

        <Desc>
          {__(
            'Chcete byť informovaní o novinkách a akciách? Zadajte svoju emailovú adresu pre odber noviniek.',
          )}
        </Desc>
      </Left>
      <Right>
        {isSubscribed ? (
          <SuccessfulWrapper>
            <SuccessfulText>
              {__('Prihlásenie na odber noviniek bolo úspešné.')}
            </SuccessfulText>
            <SuccessfulImage src="/images/done.svg" alt="" />
          </SuccessfulWrapper>
        ) : (
          <>
            <FormWrapper>
              <EmailInput
                // tslint:disable-next-line:jsx-no-lambda
                onChange={e => setEmail(e.target.value)}
                type="email"
                placeholder="Sem zadajte váš email"
              />
              <SubscribeButton
                disabled={!formIsValid()}
                type="submit"
                onClick={submitNewsletter}
              >
                {isFetching ? <Loader small={true} /> : __('Odoberať')}
              </SubscribeButton>
            </FormWrapper>
            <BottomText>
              <StyledBox onClick={handleClick} selected={termsAccepted}>
                <CheckIcon selected={termsAccepted}>
                  <SvgIcon
                    icon={IconType.checked}
                    alt="Checkbox vybraný"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill={'white'}
                  />
                </CheckIcon>
              </StyledBox>
              {__('Súhlasím so')}
              <BottomTextLink
                to={__r('routes:obchodne-podmienky', '/obchodne-podmienky')}
              >
                {' '}
                {__('spracovaním osobných údajov ')}
              </BottomTextLink>
            </BottomText>
          </>
        )}
      </Right>
    </Wrapper>
  );
};
const Wrapper = styled(FlexRowCenterVertical)`
  justify-content: flex-start;
  padding: ${rem(32)};
  margin-bottom: ${rem(48)};
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
  margin: 0 auto;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  @media only screen and (max-width: 1200px) {
    height: initial;
  }

  ${({ theme }) => theme.mediab.m760`
  flex-wrap: wrap;
`};

  ${({ theme }) => theme.mediab.m580`
   display: flex;
   margin-bottom: 0;
   height: initial;
   padding: ${rem(16)} ${rem(16)};
   border-bottom: ${rem(1)} solid ${theme.colors.white};
`};
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled(FlexCol)`
  width: 60%;
  margin-right: ${rem(10)};
  padding-left: ${rem(140)};
  padding-top: ${rem(4)};
  @media only screen and (max-width: 1050px) {
    width: 100%;
  }

  ${({ theme }) => theme.mediab.l1150`
  padding-left: ${rem(32)};
  `};
  ${({ theme }) => theme.mediab.m580`
  width: 100%;
  padding-left: 0;
  `};
`;

const Left = styled(FlexCol)`
  width: 40%;
  margin-right: ${rem(10)};
  @media only screen and (max-width: 1050px) {
    width: 100%;
  }
  ${({ theme }) => theme.mediab.m580`
   width: 100%;
   padding: ${rem(16)} 0;
`};
`;

const Title = styled.h2`
  margin: 0;
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${rem(30)};
  color: white;
  font-weight: 700;
  line-height: 38px;
  color: #3962a8;

  ${({ theme }) => theme.mediab.m580`
  line-height: 28px;
    text-align: center;
   font-size: ${rem(20)};
  `};
`;
const Desc = styled.p`
  font-size: ${rem(14)};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;
  color: black;
  line-height: 1.2;
  margin-bottom: 0;
  ${({ theme }) => theme.mediab.m760`
  margin-bottom: ${rem(16)};
  `};
`;
const TopTitleWrapper = styled(FlexRow)`
  margin-bottom: ${rem(16)};
`;

const SuccessfulWrapper = styled(FlexRowCenterVertical)`
  justify-content: center;
`;

const SuccessfulText = styled.p`
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.primary};
`;

const SuccessfulImage = styled.img`
  margin-left: ${rem(8)};
`;

const FormWrapper = styled.form`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: ${rem(16)};
  width: 100%;

  ${({ theme }) => theme.mediab.m580`
   flex-flow: column;
  `};
`;

const EmailInput = styled(Input)`
  padding: ${rem(16)} ${rem(16)} ${rem(16)} ${rem(16)};
  line-height: 1.2857142857;
  border-radius: ${rem(4)} ${rem(0)} ${rem(0)} ${rem(4)};
  max-width: initial;
  width: 100%;
  box-shadow: inset 0px 2px 4px #00000029;
  color: ${({ theme }) => theme.colors.black};
  border: ${rem(1)} solid #cccccc;
  height: ${rem(48)};

  &::placeholder {
    color: #888888;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  ${({ theme }) => theme.media(0, 'sm')} {
    width: 100%;
    padding: ${rem(10)} ${rem(16)} ${rem(11)} ${rem(16)};
    height: ${rem(40)};
    border-radius: ${rem(4)};
    font-size: ${rem(14)};
  }

  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(14)};
    padding: ${rem(16)} ${rem(16)} ${rem(16)} ${rem(16)};
  }
`;

const SubscribeButton = styled(Button)`
  transition: filter 0.2s ease;
  background: ${({ theme }) => theme.colors.tertiary};
  border-radius: ${rem(0)} ${rem(4)} ${rem(4)} ${rem(0)};
  position: relative;
  left: ${rem(-1)};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  text-transform: capitalize;
  padding: ${rem(13)} ${rem(24)} ${rem(14)} ${rem(24)};
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  height: ${rem(48)};
  filter: brightness(120%);
  &:hover {
    filter: brightness(90%);
  }

  &:disabled {
    filter: brightness(100%);
    background: ${({ theme }) => theme.colors.tertiary};
  }

  ${({ theme }) => theme.media(0, 'sm')} {
    height: ${rem(40)};
  }

  ${({ theme }) => theme.mediab.m580`
    margin-top: ${rem(8)};
    border-radius: ${rem(4)};
  `};
`;

const BottomText = styled.div`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${rem(14)};
  width: 80%;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1050px) {
    width: 100%;
  }

  ${({ theme }) => theme.mediab.m580`
  line-height: 22px;
    width: 100%;
    text-align: center;
  `};
`;

const BottomTextLink = styled(Link)`
  text-decoration: underline;
  line-height: 24px;
  margin-left: ${rem(4)};
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    opacity: 0.8;
  }
`;
const StyledBox: any = styled(FlexRowCenter)<{ selected?: boolean }>`
  border: ${({ theme, selected }) =>
    selected ? 'none' : theme.borders.secondary};
  background-color: ${({ theme, selected }) =>
    selected ? '#3962A8' : theme.colors.white};
  border-radius: ${rem(4)};
  box-shadow: inset 0px 2px 4px #00000029;
  width: ${rem(24)};
  height: ${rem(24)};
  margin-right: ${rem(12)};
  transition: all 0.2s ease;
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
const CheckIcon: any = styled(FlexRowCenter)`
  width: ${rem(16)};
  height: ${rem(16)};
  display: ${(props: any) => (props.selected ? 'flex' : 'none')};
  pointer-events: none;
`;

export default NewsletterForm;

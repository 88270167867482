import * as React from 'react';
import { connect } from 'react-redux';
import { default as API, ThenArg } from '../../services/API';
import { langSelector, titleSelector } from '../Category/selectors';
import { userSelector, contactInfoSelector } from '../App/selectors';
import Product from '../../components/Product/Product';
import { connectSsr } from 'ssr-service';
import {
  addToFavorite,
  loadProductDetail,
  loadProductDetailRelated,
  removeFromFavorite,
} from './actions';
import {
  productDataSelector,
  productDetailIsFetchingSelector,
  productAlternativesIsFetchingSelector,
  productAlternativesSelector,
} from './selectors';
import {
  SanitasInfoText as InfoText,
  FlexCol,
  SanitasLoaderWrapper as LoaderWrapper,
} from 'eshop-defaults';
import { __, __r } from 'react-i18n';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import { cartDataSelector } from '../Cart/cartSlice';
import {
  productCategoriesSelector,
  productCategoriesIsFetchingSelector,
} from '../Header/selectors';
import { gaViewItem } from '@bart.sk-ecommerce/react-online-marketing';
import { prop } from '../../utilities';
import { debounce } from 'lodash';

export interface ProductProps {
  user: ThenArg<typeof API.tokeninfo>;
  productData: ThenArg<typeof API.loadProduct>;
  dispatch: any;
  isFetching: boolean;
  lang: string;
  params: {
    product_id: number | string;
    product_url: string;
  };
  alternativeProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingAlternatives: boolean;
  cart: ThenArg<typeof API.getCart> | null;
  categories: ThenArg<typeof API.loadTree>;
  categoriesIsFetching;
}

class ProductContainer extends React.Component<ProductProps> {
  public static async getInitialProps({
    params: { product_id: productId },
    product_id,
    dispatch,
  }) {
    try {
      const correctProductId = product_id || productId;
      await Promise.all[await dispatch(loadProductDetail(correctProductId))];
      return;
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
      };
    }
  }

  // public metaHandler = lang => {
  //   const { product } = this.props;
  //   const productPublish = propByKey(product, 'publish', []).find(
  //     pp => pp.lang_id === lang,
  //   );
  //
  //   const metaTags = propByKey(productPublish, 'content.json_content.meta', {
  //     title: this.props.defaultTitle,
  //   });
  //
  //   if (!metaTags.title.length) {
  //     metaTags.title = `${this.props.defaultTitle} - ${product.name}`;
  //   }
  //
  //   return <MetaTags tags={metaTags} />;
  // };

  public addItemToCart = async (productId, goodId, count) => {
    console.log('add');
  };

  public addToWishList = debounce(async (productId, goodId, add) => {
    const { dispatch, user } = this.props;
    if (!user) {
      window.location.href = __r('routes:prihlasenie', '/prihlasenie');
    }
    if (add) {
      await Promise.all([
        dispatch(addToFavorite(productId, goodId)),
        dispatch(loadProductDetail(productId, false)),
      ]);
    } else {
      await Promise.all([
        dispatch(removeFromFavorite(productId, goodId)),
        dispatch(loadProductDetail(productId, false)),
      ]);
    }
  }, 300);

  public async componentDidUpdate(prevProps: ProductProps) {
    const { productData, dispatch } = this.props;
    if (productData && prevProps.productData !== productData) {
      gaViewItem(this.props.productData, undefined);

      const mainCategoryId = prop(
        productData,
        'productCategories.0.category_id',
        null,
      );
      if (mainCategoryId) {
        await dispatch(loadProductDetailRelated(mainCategoryId));
      }
    }
  }

  public render() {
    const {
      productData,
      isFetching,
      alternativeProducts,
      isFetchingAlternatives,
      dispatch,
      user,
      cart,
      categories,
      categoriesIsFetching,
    } = this.props;

    if (isFetching) {
      return <LoaderWrapper height={300} />;
    }

    if (!productData) {
      return (
        <Wrapper className="container container--wide">
          <InfoText info={__('Produkt nebol nájdený')} type={InfoType.ERROR} />
        </Wrapper>
      );
    }

    return (
      <Product
        product={productData}
        isFetching={isFetching}
        dispatch={dispatch}
        alternativeProducts={alternativeProducts}
        isFetchingAlternatives={isFetchingAlternatives}
        user={user}
        cart={cart}
        categories={categories}
        categoriesIsFetching={categoriesIsFetching}
        addToWishList={this.addToWishList}
      />
    );
  }
}

const Wrapper = styled(FlexCol)`
  padding: ${rem(56)} ${rem(24)} ${rem(80)};
  color: #444444;
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
      padding: ${rem(32)} 0;
  `}
`;

const mapStateToProps = state => {
  return {
    user: userSelector(state),
    lang: langSelector(state),
    defaultTitle: titleSelector(state),
    contactInfo: contactInfoSelector(state),
    productData: productDataSelector(state),
    isFetching: productDetailIsFetchingSelector(state),
    alternativeProducts: productAlternativesSelector(state),
    isFetchingAlternatives: productAlternativesIsFetchingSelector(state),
    cart: cartDataSelector(state),
    categories: productCategoriesSelector(state),
    categoriesIsFetching: productCategoriesIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ProductContainer' })(ProductContainer),
);

import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexRow,
  FlexCol,
  NavigationSanitasDropdownItem as NavigationDropdownItem,
  OutsideClick,
  prop,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { getImagePath } from '../../utilities/product';
import { generateUrlFromString } from 'eshop-defaults/lib/utilities/selectors';
import BlogMenuItem from '../Header/BlogMenuItem';

export interface Props {
  user?: any;
  items: ThenArg<typeof API.loadTree>;
  location?: any;
}

function SanitasNavigation({ items, user, location }: Props) {
  if (items) {
    items.sort((a, b) =>
      a.category_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') >
      b.category_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        ? 1
        : b.category_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') >
          a.category_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        ? -1
        : 0,
    );

    items.sort((a, b) => prop(b, 'is_favorite', 0) - prop(a, 'is_favorite', 0));
  }

  const [activeFirstItem, setActiveFirstItem] = useState<number>(-1);
  const [activeSecondItem, setActiveSecondItem] = useState<number>(-1);
  const [activeThirdItem, setActiveThirdItem] = useState<number>(-1);
  const [secondLvlItems, setSecondLvlItems] = useState<any[] | null>(null);
  const [thirdLvlItems, setThirdLvlItems] = useState<any[] | null>(null);

  const onMouseOverSecond = id => {
    if (id || id === 0) {
      if (
        items &&
        items[id] &&
        items[id].children &&
        items[id].children.length > 0
      ) {
        setSecondLvlItems(items[id].children);
      } else {
        setSecondLvlItems(null);
        setActiveSecondItem(-1);
      }
      setThirdLvlItems(null);
      setActiveFirstItem(id);
    }
  };

  const onMouseOverThird = id => {
    if (id || id === 0) {
      if (
        secondLvlItems &&
        secondLvlItems[id] &&
        secondLvlItems[id].children &&
        secondLvlItems[id].children.length > 0
      ) {
        setThirdLvlItems(secondLvlItems[id].children);
      } else {
        setThirdLvlItems(null);
        setActiveThirdItem(-1);
      }
      setActiveSecondItem(id);
    }
  };

  const closeNav = () => {
    resetState();
  };

  const resetState = () => {
    setActiveFirstItem(-1);
    setActiveSecondItem(-1);
    setSecondLvlItems(null);
    setThirdLvlItems(null);
  };

  const listIconSrc = '/images/arrow_dark.svg';
  const placeholderImgSrc = '/images/zemplin/placeholder.svg';

  return (
    <>
      <OutsideClick handleFunction={closeNav}>
        <Wrapper onMouseLeave={closeNav}>
          <FirstHoverWrapper>
            <FirstItemsWrapper>
              {items &&
                items.map((category: any, i) => {
                  if (!category.counts || category.counts === 0) {
                    return null;
                  }
                  return (
                    <NavigationDropdownItem
                      index={i}
                      onClick={closeNav}
                      key={category.category_id}
                      id={category.category_id}
                      url={category.url}
                      name={category.category_name}
                      imgSrc={
                        category.image
                          ? getImagePath(
                              category.image,
                              {
                                width: 32,
                                height: 32,
                              },
                              false,
                              false,
                              false,
                            )
                          : placeholderImgSrc
                      }
                      onMouseOver={onMouseOverSecond}
                      firstLevel={true}
                      active={activeFirstItem === i}
                      lastItem={i === items.length - 1}
                      numOfCat={category.children.length > 0}
                      listIconSrc={listIconSrc}
                    />
                  );
                })}
            </FirstItemsWrapper>
            {secondLvlItems && (
              <SecondHover className="showMe">
                <OtherItemsWrapper>
                  {secondLvlItems &&
                    secondLvlItems.map((category: any, i) => {
                      if (!category.counts || category.counts === 0) {
                        return null;
                      }
                      return (
                        <NavigationDropdownItem
                          onClick={closeNav}
                          index={i}
                          key={category.category_id}
                          id={category.category_id}
                          url={category.url}
                          name={category.category_name}
                          imgSrc={
                            category.image
                              ? getImagePath(
                                  category.image,
                                  {
                                    width: 32,
                                    height: 32,
                                  },
                                  false,
                                  false,
                                  false,
                                )
                              : placeholderImgSrc
                          }
                          onMouseOver={onMouseOverThird}
                          firstLevel={false}
                          active={activeSecondItem === i}
                          lastItem={i === items.length - 1}
                          numOfCat={category.children.length > 0}
                          listIconSrc={listIconSrc}
                        />
                      );
                    })}
                </OtherItemsWrapper>
                {secondLvlItems && thirdLvlItems && (
                  <ThirdHover>
                    <OtherItemsWrapper>
                      {thirdLvlItems.map((category: any, i) => {
                        if (!category.counts || category.counts === 0) {
                          return null;
                        }
                        let isCurrent = false;
                        return (
                          <NavigationDropdownItem
                            index={i}
                            onClick={closeNav}
                            key={category.category_id}
                            id={category.category_id}
                            url={category.url}
                            name={category.category_name}
                            imgSrc={
                              category.image
                                ? getImagePath(
                                    category.image,
                                    {
                                      width: 40,
                                      height: 40,
                                    },
                                    false,
                                    false,
                                    false,
                                  )
                                : placeholderImgSrc
                            }
                            firstLevel={false}
                            active={activeThirdItem === i}
                            lastItem={i === items.length - 1}
                            listIconSrc={listIconSrc}
                          />
                        );
                      })}
                    </OtherItemsWrapper>
                  </ThirdHover>
                )}
              </SecondHover>
            )}
          </FirstHoverWrapper>
          <StyledBlogMenuItem />
        </Wrapper>
      </OutsideClick>
    </>
  );
}

const Wrapper = styled(FlexRowCenterVertical)`
  position: absolute;
  overflow: hidden;
  border-radius: ${rem(8)};
  display: block;
`;

const FirstHoverWrapper = styled(FlexRow)`
  background-color: #e0e7f0;
  z-index: 111;
`;

const FirstItemsWrapper = styled(FlexCol)`
  width: ${rem(328)};
  max-height: ${rem(704)};
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: ${rem(16)};
  line-height: ${rem(21)};
  z-index: 111;
`;

const OtherItemsWrapper = styled(FirstItemsWrapper)`
  box-shadow: initial;
  overflow-y: auto;
`;

const SecondHover = styled(FirstHoverWrapper)`
  background-color: #d3ddea;
`;

const ThirdHover = styled(FlexRow)`
  background-color: #c5d2e5;
  overflow-y: auto;

  z-index: 111;
`;
const StyledBlogMenuItem = styled(BlogMenuItem)`
  margin-top: ${rem(680)};
`;

export { SanitasNavigation };
